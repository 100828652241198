export const PrimaryColors = [
  {
    name: 'contessa',
    color: '#D3A096',
  },
  {
    name: 'flamingo',
    color: '#EC8A7D',
  },
  {
    name: 'pinot',
    color: '#E185BC',
  },
  {
    name: 'orchid',
    color: '#DE90E2',
  },
  {
    name: 'plum',
    color: '#CC9ED4',
  },
  {
    name: 'indigo',
    color: '#8CA0CE',
  },
  {
    name: 'sky',
    color: '#32B0E5',
  },
  {
    name: 'paradiso',
    color: '#60A0A1',
  },
  {
    name: 'amazon',
    color: '#66A182',
  },
  {
    name: 'wasabi',
    color: '#A9C15A',
  },
  {
    name: 'slate',
    color: '#94A3B8',
  },
]

export const Partners = {
  AMBASSADOR: 'ambassador',
  CONSULTANT: 'consultant',
  RESELLER: 'reseller',
  SOLUTION_PARTNER: 'solution-partner',
} as const
